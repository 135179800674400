<template>
    <div class="relative p-10 bg-white rounded-lg shadow-lg">
        <LoadingComponent :isLoading="isLoading" />
        <div class="divide-y divide-gray-100 divide-solid">
            <Profile
                v-if="Object.keys(providerData).length > 0"
                ref="profile"
                :isProvider="true"
                :profileFormData.sync="profileForm"
                :profileFormValidate.sync="profileFormValidate"
            />
            <About
                v-if="Object.keys(providerData).length > 0"
                ref="about"
                :isAdd="true"
                :aboutFormData.sync="aboutForm"
                :aboutFormValidate.sync="aboutFormValidate"
                :chooseActivities="providerDefaultChooseData.activities"
                :chooseSkills="providerDefaultChooseData.skills"
            />
            <Service
                v-if="!$isEmpty(providerDefaultChooseData.categories)"
                ref="service"
                :deafaulAllSelectHour="true"
                :isAdd="true"
                :checkBusinessHoursList="checkBusinessHoursList"
                :categoriesFormData.sync="categoriesForm"
                :serviceFormValidate.sync="serviceFormValidate"
                :chooseCategories="providerDefaultChooseData.categories"
            />
            <IdCard
                v-if="!$isEmpty(providerIdCard.frontPhoto)"
                :idCard="providerIdCard"
            />
            <Salary
                ref="salary"
                :isAdd="true"
                :onUploadBankPhotoSuccess.sync="salaryBankPhotoUplaodSuccess"
                :salaryFormData.sync="salaryForm"
                :salaryFormValidate.sync="salaryFormValidate"
            />
        </div>
        <div class="flex justify-center mt-10">
            <!-- <button @click.prevent="$router.push({name:'provider_list'})"
              class="black-btn md:text-sm text-xs max-w-[150px] flex-grow mr-3">取消</button> -->
            <btn
                v-permission="['create']"
                color="border border-red-500 text-red-500"
                customClass="mr-3"
                @onClick="onSubmit('form', 4)"
            >
                資料不完整
            </btn>
            <btn
                v-permission="['create']"
                color="border border-red-500 text-red-500"
                customClass="mr-3"
                @onClick="onSubmit('form', providerData.role)"
            >
                儲存
            </btn>
            <btn v-permission="['create']" @onClick="onSubmit('form', 1)">
                上架
            </btn>
            <!-- <button @click.prevent="onSubmit('form')"
              class="yellow-btn-500 md:text-sm text-xs mr-1 max-w-[150px] flex-grow">新增</button> -->
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Profile from "./components/Profile.vue";
import About from "./components/about/index.vue";
import Service from "./components/Service.vue";
import Salary from "./components/salary/index.vue";
// 旋轉動畫
import LoadingComponent from "@/components/Loading.vue";
// 導入 firebase 更新聊天室全部聊天對象資料方法
import firebaseChatMixin from "@/service/firebaseChatMixin";
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";
// 服務商身分證顯示
import IdCard from "./components/ProviderIdCard.vue";

export default {
    name: "AddProvider",
    mixins: [firebaseChatMixin],
    components: {
        Profile,
        About,
        Service,
        Salary,
        LoadingComponent,
        Btn,
        IdCard,
    },
    computed: {
        ...mapState("providerStore", ["providerData"]),
    },
    data() {
        return {
            // profile 表單資料
            profileForm: {},
            // about 資料
            aboutForm: {},
            // 分類表單資料
            categoriesForm: {},
            // 每日營業時間資料
            checkBusinessHoursList: {
                1: [],
                2: [],
                3: [],
                4: [],
                5: [],
                6: [],
                7: [],
            },
            // 薪轉表單資料
            salaryForm: {},
            // 更新動畫
            isLoading: false,
            // 判斷 profile 表單是否驗證正確 預設值為 true
            profileFormValidate: true,
            // 判斷 about 表單是否驗證正確 預設值為 true
            aboutFormValidate: true,
            // 判斷 service 表單是否有通過驗證 預設值為 true
            serviceFormValidate: true,
            // 判斷 salary 表單驗證是否有通過驗證 預設為 true
            salaryFormValidate: true,
            // 判斷 存摺是否有上傳成功  預設為 true
            salaryBankPhotoUplaodSuccess: true,
            // 服務商身分證資料
            providerIdCard: {
                frontPhoto: "",
                backPhoto: "",
            },
            // 服務商預設選取資料
            providerDefaultChooseData: {},
        };
    },
    methods: {
        ...mapMutations("providerStore", ["setProviderData"]),

        /**
         * 取得當前供給者資料
         */
        async getData() {
            try {
                // 取得供給者資料
                const { data } = await this.$api.GetUserDataApi(
                    this.$route.params.id
                );
                // 設定 供給者 資料

                this.setProviderData(data);
                return data;
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "取得使用者資料失敗",
                });
            }
        },
        /**
         * 取得成為服務商預設值資料
         */
        async getProviderDefaultChooseData() {
            const { data } = await this.$api.GetProviderDefaultChooseDataApi();
            this.providerDefaultChooseData = data;
            this.providerDefaultChooseData.categories = data.categories.map(
                (item) => {
                    return {
                        ...item,
                        status: 0,
                    };
                }
            );
        },
        /**
         * 表單發送
         * @param { type Strin(字串) } formName 表單名稱
         * @param { type Number(數字) } role 服務商身份狀態
         */
        async onSubmit(formName, role) {
            try {
                // 觸發 profile 子組件 onSubmit 方法
                await this.$refs.profile.onSubmit(formName);
                // 觸發 about 子組件 onSubmit 方法
                await this.$refs.about.onSubmit(formName);
                // 觸發 service 子組件 onSubmit 方法
                await this.$refs.service.onSubmit(formName);
                // 觸發 salary 子組件 onSubmit 方法
                await this.$refs.salary.onSubmit(formName);
                // 判斷表單驗證是否有通過
                if (
                    !this.aboutFormValidate ||
                    !this.profileFormValidate ||
                    !this.serviceFormValidate ||
                    !this.salaryFormValidate ||
                    !this.salaryBankPhotoUplaodSuccess
                ) {
                    return;
                }
                this.add({
                    ...this.aboutForm,
                    ...this.profileForm,
                    role,
                    banking: { ...this.salaryForm },
                });
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "新增失敗",
                });
            }
        },
        // 確認是否全部分類關閉
        checkCategoriesHaveAllClose() {
            return new Promise((resolve, reject) => {
                const isClosedCategory = Object.keys(
                    this.categoriesForm
                ).filter((objKey) => {
                    return this.categoriesForm[objKey].status > 0;
                });
                if (isClosedCategory.length === 4) {
                    reject(false);
                } else {
                    resolve(true);
                }
            });
        },
        /**
         * 新增服務商
         * @param { type Object(物件) } form 服務商資料
         */
        async add(form) {
            this.isLoading = true;
            console.log("update form => ", form);
            try {
                await this.checkCategoriesHaveAllClose();
                try {
                    await this.$api.UpdateProfileApi(
                        this.$route.params.id,
                        form
                    );
                    await this.updateAllCategories(this.$route.params.id, {
                        categories: this.categoriesForm,
                    });
                    await this.updateBusinessHour();
                    this.isLoading = false;
                    this.$message({
                        type: "success",
                        message: "新增成功",
                    });
                    // // 重新取得使用者資料
                    const userData = await this.getData();
                    // // 更新firebase 聊天對象中屬於目前修改對象的資料
                    await this.updateFirebaseChatRoomUserData(
                        userData.banana_id,
                        userData
                    );
                    this.$router.push({
                        name: "provider_update",
                        params: { id: this.$route.params.id },
                    });
                } catch (err) {
                    console.log(err);
                    this.isLoading = false;
                    this.$message({
                        type: "error",
                        message: "新增失敗",
                    });
                }
            } catch (err) {
                this.isLoading = false;
                this.$message({
                    type: "error",
                    message: "至少開啟一個服務項目",
                });
            }
        },
        /**
         * 更新服務時間
         * @param { type Strign(字串) } userId 始用者id
         * @param { type Array(物件) } form 服務時間
         */
        async updateBusinessHour() {
            const req = [];
            for (const key in this.checkBusinessHoursList) {
                req.push({
                    day: key,
                    hours: this.checkBusinessHoursList[key],
                });
            }
            try {
                await this.$api.UpdateWeeklyBusinessHours(
                    this.$route.params.id,
                    {
                        weekdays: req,
                    }
                );
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "新增服務時間失敗",
                });
            }
        },
        /**
         * 更新分類
         * @param { type Strign(字串) } userId 始用者id
         * @param { type Object(物件) } form 服務時間
         */
        async updateAllCategories(userId, form) {
            try {
                await this.$api.UpdateOrCreateAllCategories(userId, form);
            } catch (err) {
                this.$message({
                    type: "error",
                    message: `新增分類失敗`,
                });
            }
        },
        /**
         * 取得服務商身分證資料
         */
        async getProviderIdCard(userId) {
            try {
                const { data: providerIdCard } =
                    await this.$api.GetUserIdCardApi(userId);
                // 判斷沒有資料時給空值
                if (
                    this.$isEmpty(providerIdCard.attachment) ||
                    this.$isEmpty(providerIdCard.attachment.id_card_front)
                ) {
                    return (this.providerIdCard = {
                        frontPhoto: null,
                        backPhoto: null,
                    });
                }
                this.providerIdCard = {
                    frontPhoto: providerIdCard.attachment.id_card_front.url,
                    backPhoto: providerIdCard.attachment.id_card_back.url,
                };
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "取得服務商身分證資料失敗",
                });
            }
        },
    },
    async created() {
        await this.getProviderDefaultChooseData();
        await this.getData();
        await this.getProviderIdCard(this.$route.params.id);
    },
    destroyed() {
        this.setProviderData({});
    },
};
</script>
